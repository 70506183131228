import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import DesignOptions from '../../components/designOptions/designOptions';
import axios from 'axios';
import { API_URL } from '../../_config';
import Slider from "react-slick";
import './design.scss'
import { FaTimes, FaDownload, FaArrowLeft } from 'react-icons/fa';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { CgScrollV } from 'react-icons/cg';
import { useHistory } from 'react-router';
import Loader from "../../components/loader";
import { jsPDF } from "jspdf";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setTimeout } from 'timers';
import Modal from '../../components/userModal';
import ReactTooltip from 'react-tooltip'
import toast, { Toaster } from 'react-hot-toast';




export default function Design() {
    const history = useHistory();
    const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, 297]
    });


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true
    };

    const THREE = require('three');
    const OBJLoaderModule = require('../../threejs/OBJLoader.js');
    const OrbitControlsModule = require('../../threejs/OrbitControls.js');
    const OBJLoader = OBJLoaderModule.OBJLoader;
    const OrbitControls = OrbitControlsModule.OrbitControls;


    const [scene, setScene] = useState<any>(null);
    const [camera, setCamera] = useState<any>(null);
    const [renderer, setRenderer] = useState<any>(null);
    const [render, setRender] = useState<boolean>(false);
    const [workingLoader, setWorkingLoader] = useState<boolean>(true);

    const [loader, setLoader] = useState<any>(null);
    const [manager, setManager] = useState<any>(null);
    const [object, setObject] = useState<any>(null);
    const [texture, setTexture] = useState<any>(null);
    const [textureCanvas, setTextureCanvas] = useState<any>(null);
    const [colors, setColors] = useState([]);
    const [textureColors, set_textureColors] = useState([]);
    const [logo, setLogo] = useState<string | null>('/assets/images/logo-white.png');
    const [design, setDesign] = useState<any | null>(null);
    const [logoSize, setLogoSize] = useState<any>('medium');
    const [logoPosition, setLogoPosition] = useState<any>('left');
    const [currentTexture, setCurrentTexture] = useState<any>(null);
    const [model, setModel] = useState<any>(null);
    const [control, setControl] = useState<any>(null);

    const [localImages, setLocalImages] = useState<any>({});
    const [mount, setMount] = useState<boolean>(true);


    const [ownerPallete, setOwnerPallete] = useState<boolean>(false);
    const [inputColor, setInputColor] = useState<any>(null);


    const [textureMapImage, set_textureMapImage] = useState<any>(null);

    const logoSizes: any = {
        small: { width: 30, height: 30 },
        medium: { width: 70, height: 70 },
        big: { width: 100, height: 100 },
    }

    const ownerColors:any = ['#2f5e47','#38d964','#d4fa3e','#ff80a6','#1c4d8c','#f5fc26','#6ca9d9','#91eded','#990f3d','#ff8021','#b31b72','#eb070c','#35c4bb','#732479','#1b215f','#090d0d']




    const colorsName: any = {
        '#2f5e47': 'Dark Green',
        '#38d964': 'Green',
        '#d4fa3e': 'Bright Green',
        '#ff80a6': 'Pink',
        '#1c4d8c': 'Royal Blue',
        '#f5fc26': 'Yellow',
        '#6ca9d9': 'Alpine Blue',
        '#91eded': 'Light Blue',
        '#990f3d': 'Maroon',
        '#ff8021': 'Orange',
        '#b31b72': 'Pink Purple',
        '#eb070c': 'Red',
        '#35c4bb': 'Teal',        
        '#732479': 'Purple',        
        '#1b215f': 'Dark Blue',
        '#090d0d': 'Black',

        '#b89337': 'color Name',
        '#d3e34c': 'color Name',
        '#fa467d': 'color Name',
        '#bf344d': 'color Name',
        '#c3a9ce': 'color Name',
        '#ff9b0b': 'color Name',
        '#cacacc': 'color Name',
        '#78f078': 'color Name',
        '#a6146a': 'color Name',
        '#348e6f': 'color Name',
        
    }





    const [modal, setModal] = useState<boolean>(false);
    const modalClose = () => {
        setModal(false);
    }
    //var a = { "small": { "left": { "x": 280, "y": 600 }, "right": { "x": 160, "y": 600 }, "center": { "x": 218, "y": 600 } }, "medium": { "left": { "x": 260, "y": 600 }, "right": { "x": 160, "y": 600 }, "center": { "x": 210, "y": 600 } }, "big": { "left": { "x": 235, "y": 600 }, "right": { "x": 135, "y": 600 }, "center": { "x": 180, "y": 600 } } }



    /* var model: any = {
        model: '/assets/model/t5.obj',
        modelNo: 'M201548',
        id: '256',
        logoPosition: {
            small: { left: { x: 280, y: 600 }, right: { x: 160, y: 600 }, center: { x: 218, y: 600 } },
            medium: { left: { x: 260, y: 600 }, right: { x: 160, y: 600 }, center: { x: 210, y: 600 } },
            big: { left: { x: 235, y: 600 }, right: { x: 135, y: 600 }, center: { x: 180, y: 600 } },
        },

        patterns: [
            {
                thumb: '/assets/fakePath/p1.png',
                textures: [
                    {
                        map: '/assets/fakePath/texture/texture-1A.png',
                        colors: ['#fffb00', '#f042e7']
                    },
                    {
                        map: '/assets/fakePath/texture/texture-1B.jpg',
                        colors: ['#ff0000', '#124fc2']
                    },
                    {
                        map: '/assets/fakePath/texture/texture-1C.jpg',
                        colors: ['#124fc2', '#e94ee1']
                    }

                ]
            },
            {
                thumb: '/assets/fakePath/p2.png',
                textures: [
                    {
                        map: '/assets/fakePath/texture/texture-2.png',
                        colors: ['#0ead70', '#5cc0da', '#ec72dc', '#ec7d33']
                    }

                ]
            },
            {
                thumb: '/assets/fakePath/p3.png',
                textures: [
                    {
                        map: '/assets/fakePath/texture/texture-3A.png',
                        colors: ['#0b9986', '#124fc2']
                    },
                    {
                        map: '/assets/fakePath/texture/texture-3B.jpg',
                        colors: ['#ff0000', '#124fc2']
                    },

                ]
            },
            {
                thumb: '/assets/fakePath/p4.png',
                textures: [
                    {
                        map: '/assets/fakePath/texture/texture-4A.png',
                        colors: ['#ff0000', '#124fc2']
                    },
                    {
                        map: '/assets/fakePath/texture/texture-4B.jpg',
                        colors: ['#ff0000', '#eeff07']
                    },
                    {
                        map: '/assets/fakePath/texture/texture-4C.jpg',
                        colors: ['#eeff07', '#bf38c4']
                    }

                ]
            }
        ],
    } */

    const getModel = () => {
        axios({
            method: 'GET',
            url: API_URL.PRODUCT.GET_MODEL + sessionStorage.getItem('modelID'),
        }).then(res => {
            if (res && res.data && res.data.data) {
                let product = res.data.data.product;
                product.logo_position = JSON.parse(product.logo_position.replace(/\r/g, '').replace(/\n/g, ''))
                setModel(product)
            } else {
                setModel(null)
            }
        }).catch(err => {
            setModel(null)
        })
    }


    

    const loadModel = () => {
        if (object) {
            /* let textureLoader = new THREE.TextureLoader(manager);
            const groundTexture = textureLoader.load('/assets/model/t5-tex.jpg'); */
            object.traverse(function (child: any) {
                if (child.isMesh) {
                    /* 
                    child.material.side = THREE.BackSide;
                    child.material.shininess = 10
                    child.material.map = groundTexture;//texture
                    */
                    child.material.side = THREE.DoubleSide;
                    child.material.shininess = 10
                    child.material.map = texture;//texture
                    child.castShadow = true;
                }
            });
            object.position.y = -300;
            object.scale.set(52, 52, 52);
            scene.add(object);
        }
    }

    const onProgress = (xhr: any) => {
        if (xhr.lengthComputable) {
            const percentComplete = xhr.loaded / xhr.total * 100;
            console.log('model ' + Math.round(percentComplete) + '% downloaded');
        }
    }

    const onError = () => { }

    const getReady = () => {
        addObject();
        loadTexture().then(()=>{setWorkingLoader(false);})
    }

    const addObject = () => {
        
        loader.crossOrigin = '';
        loader.load(model.threed_img.original, function (obj: any) { //'/assets/model/t5.obj'
            setObject(obj);
        }, onProgress, onError);
    }

    const addGround = () => {
        // ground
        /* let textureLoader = new THREE.TextureLoader(manager);
        const groundTexture = textureLoader.load('/assets/textures/gery.webp');
        groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
        groundTexture.repeat.set(100, 100);
        groundTexture.anisotropy = 16; */
        //groundTexture.encoding = THREE.sRGBEncoding;
        //const groundMaterial = new THREE.MeshLambertMaterial({ map: groundTexture });
        const groundMaterial = new THREE.MeshPhongMaterial({ color: 0xf4f4f4, depthWrite: false })
        let mesh = new THREE.Mesh(new THREE.PlaneGeometry(20000, 20000), groundMaterial);
        mesh.position.y = - 250;
        mesh.rotation.x = - Math.PI / 2;
        mesh.receiveShadow = true;
        scene.add(mesh);
    }

    const addLight = () => {
        scene.add(new THREE.AmbientLight(0xf4f4f4));

        const light = new THREE.DirectionalLight(0xe4e4e4, 0.2);
        light.position.set(0, 500, 20);
        light.position.multiplyScalar(1.3);
        light.castShadow = true;
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;

        const d = 300;
        light.shadow.camera.left = - d;
        light.shadow.camera.right = d;
        light.shadow.camera.top = d;
        light.shadow.camera.bottom = - d;
        light.shadow.camera.far = 1000;
        scene.add(light);

        /* const helper = new THREE.CameraHelper(light.shadow.camera);
        scene.add(helper); */
    }

    const setControls = () => {
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enablePan = false;
        controls.enableZoom = true;
        controls.target.set(0, 1, 0);
        controls.maxPolarAngle = Math.PI * 0.5;
        controls.minDistance = 1000;
        controls.maxDistance = 5000;
        controls.update();
        setControl(controls);
        //controls.enabled = false;

        /* let canvas: any = document.getElementById('canvas');
        canvas.addEventListener('touchstart',(e:any)=>{
            if(e.targetTouches.length == 1){
                controls.enabled = false;
                controls.dispose();
            }
        });
        canvas.addEventListener('touchend',(e:any)=>{
            //controls.enabled = true;
        }) */
    }

    const getCanvas = (prop: string) => {
        let canvas: any = document.getElementById("canvas");
        if (canvas) {
            let style = window.getComputedStyle(canvas, null);
            switch (prop) {
                case "width":
                    return (canvas.clientWidth - parseFloat(style.getPropertyValue("padding-left")) - parseFloat(style.getPropertyValue("padding-left")));
                case 'height':
                    return (canvas.clientHeight - parseFloat(style.getPropertyValue("padding-top")) - parseFloat(style.getPropertyValue("padding-bottom")));
                default:
                    return 0
            }
        }
        return 0
    }

    const prepareScene = () => {

        setRender(true)
        let canvas: any = document.getElementById('canvas');

        // scene
        scene.background = new THREE.Color(0xffffff);
        //scene.fog = new THREE.Fog(0xffffff, 500, 10000);

        addLight();
        addGround();

        // camera
        camera.position.set(1000, 50, 1500);
        scene.add(camera);

        getReady();

        if (canvas) {
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(getCanvas('width'), getCanvas('height'));
            renderer.shadowMap.enabled = true;
            renderer.setAnimationLoop(animation);
            canvas?.appendChild(renderer.domElement);
        }
        window.addEventListener('resize', onWindowResize);
        setControls();
    }

    const onWindowResize = () => {
        camera.aspect = getCanvas('width') / getCanvas('height');
        camera.updateProjectionMatrix();
        renderer.setSize(getCanvas('width'), getCanvas('height'));
    }

    const animation = (time: any) => {
        if (mount) {
            renderer.render(scene, camera);
        }

    }

    const loadTexture = () => {
        return new Promise((resolve, reject) => {
            setTexture(new THREE.Texture(textureCanvas));
            resolve(true);
        })
        
    }

    const ImagetoDataURL = (url: string) => fetch(API_URL.PRODUCT.GET_IMAGE_DATA + url)
        .then(response => response.text())
        .then(dataUrl => new Promise((resolve, reject) => {
            resolve(dataUrl);
        }))

    const setLocalImage = (url: any, dataUrl: any) => {
        if (localImages[url] === undefined) {
            let _localImages = JSON.parse(JSON.stringify(localImages));
            _localImages[url] = dataUrl;
            setLocalImages(_localImages)
        }

    }

    const getBase64FromImage = (url: string, onSuccess: any, onError: any) => {
        if (localImages[url] !== undefined) {
            var _img: any = new Image();
            _img.onload = () => {
                onSuccess(_img);
            }
            _img.src = localImages[url];
        } else {
            var xhr = new XMLHttpRequest();
            if (url.search('http') === 0) {
                ImagetoDataURL(url)
                    .then((dataUrl: any) => {
                        setLocalImage(url, dataUrl);
                        var _img: any = new Image();
                        _img.onload = () => {
                            onSuccess(_img);
                        }
                        _img.src = dataUrl;
                    })

            } else {
                xhr.responseType = "arraybuffer";
                xhr.open("GET", url);
                xhr.onload = function () {
                    var base64, binary, bytes, mediaType;
                    bytes = new Uint8Array(xhr.response);
                    binary = [].map.call(bytes, function (byte) {
                        return String.fromCharCode(byte);
                    }).join('');
                    mediaType = xhr.getResponseHeader('content-type');
                    base64 = [
                        'data:',
                        mediaType ? mediaType + ';' : '',
                        'base64,',
                        btoa(binary)
                    ].join('');
                    setLocalImage(url, base64);
                    var _img: any = new Image();
                    _img.onload = () => {
                        onSuccess(_img);
                    }
                    _img.src = base64;
                };
                xhr.onerror = onError;
                xhr.send();
            }
        }



    }

    const createTexture = (images: any) => {
        return new Promise((resolve, reject) => {
            if (textureCanvas) {
                var ctx = textureCanvas.getContext('2d');
                ctx.clearRect(0, 0, textureCanvas.width, textureCanvas.height);
                let loaded = 0;
                images.forEach((image: any) => {
                    getBase64FromImage(image.path, (img: any) => {
                        image.imageObj = img;
                        loaded++;
                        if (loaded === images.length) {
                            images.forEach((image: any) => {
                                let img = image.imageObj;
                                ctx.globalAlpha = img.alpha;
                                ctx.drawImage(img, 0, 0, img.width, img.height, image.x, image.y, image.width, image.height);

                            });
                            resolve(textureCanvas.toDataURL('image/jpeg', 1.0));
                        }
                    }, () => { })
                });
            }
        });
    }

    const loadDesign = (pattern: any) => {
        toast('You can change the colors to see how it looks. System can’t generate the actual always. No worries! \n Keep your color code in mind. You will be delivered the exact that you expected.',{duration:4000})
        setDesign(pattern);
        setColors(pattern.texture_imges);
        set_textureColors(pattern.texture_imges[0].color_codes.split(','));
        set_textureMapImage(pattern.texture_imges[0].original)
        setCurrentTexture(pattern.texture_imges[0]);
    }

    const getLogo = () => {
        let logoData = { path: logo, ...logoSizes[logoSize], ...model.logo_position[logoSize][logoPosition], alpha: 1 }
        return logoData;
    }

    const changeTextureColor = () => {
        let exe:any  = false;
        let timer = setTimeout(() => {
            if(!exe){
                setWorkingLoader(true);
            }
            
        },300)
        let map = textureMapImage ? textureMapImage : '/assets/textures/white.jpg';
        createTexture([
            { path: map, width: 1024, height: 1024, x: 0, y: 0, alpha: 1 },
            getLogo(),
        ]).then((data) => {
            loadTexture().then(()=>{
                exe =true;
                clearTimeout(timer)
                setWorkingLoader(false);})
        })
    }

    const createVariables = () => {
        setScene(new THREE.Scene());
        setCamera(new THREE.PerspectiveCamera(30, getCanvas('width') / getCanvas('height'), 1, 10000));
        setRenderer(new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true }));
        setManager(new THREE.LoadingManager());

    }

    const downloadTshirt = () => {
        if (design) {
            camera.aspect = 500 / 500;
            camera.updateProjectionMatrix();
            renderer.setSize(500, 500);
            renderer.render(scene, camera);
            camera.lookAt(0, 0, 0);

            camera.position.set(0, 0, 1500);
            control.update();
            setTimeout(() =>{
                let frontView = renderer.domElement.toDataURL("image/jpeg");
                camera.position.set(0, 0, -1500);
                control.update();
                setTimeout(() =>{
                    let backView = renderer.domElement.toDataURL("image/jpeg");
                    camera.position.set(1500, 0, 0);
                    control.update();
                    setTimeout(() =>{
                        let sideView = renderer.domElement.toDataURL("image/jpeg"); 

                        let _W = 90;
                        let _H = 90;

                        doc.addImage(frontView, 'JPEG', 15, 15, _W, _H);
                        doc.addImage(backView, 'JPEG', 15+_W, 15, _W, _H);
                        doc.addImage(sideView, 'JPEG', 15, 15+ _H, _W, _H);

                        let x = 15+_W - 20;
                        let y = 15+_H;
                        doc.setFontSize(18);
                        doc.text(model.title,x , y);

                        doc.setFontSize(16);
                        doc.setTextColor(255, 0, 0);
                        doc.text('#' + model.model_id, x, y + 6);

                        doc.setFontSize(10);
                        doc.setTextColor(255, 145, 0);
                        doc.text(design.title, x, y + 11);

                        doc.setFontSize(10);
                        doc.setTextColor(22, 22, 22);
                        doc.text(model.description, x, y + 16);


                        doc.setFillColor('#222222');
                        doc.circle(x + 4, y + 24, 4, 'F');


                        doc.save(model.model_id + '_' + design.title + ".pdf");

                        //---- PDF END ---------
                        setTimeout(() =>{
                            onWindowResize();
                        },100)
                    },100)
                },100)
            },100)
        } else {
            alert('please select a design')
        }

    }

    const back = () => {
        renderer.setAnimationLoop(null);
        setScene(null);
        setCamera(null);
        setRenderer(null);
        setManager(null);
        setMount(false);
        history.push('/select-design');
    }


    //---------- Track ------------
    const trackDown = () => {
        let slideTrack = document.querySelector('.slide-track');
        let track: any = document.querySelector('.track');

        if (slideTrack && track) {
            let style = getComputedStyle(track);
            let sliderType:string = style.getPropertyValue('flex-direction');

            if(sliderType == 'row'){
                let W = slideTrack.clientWidth;
                let SW = slideTrack.scrollWidth;
                let S = SW - W;

                let L = parseInt(getComputedStyle(track, null).getPropertyValue("left"));
                let R = S + L;

                let scroll = 0;
                if(L < 0){
                    if (-L > W) {
                        scroll += W;
                    } else {
                        scroll -= L;
                    }
                } 

                track.style.left = (L + scroll) + 'px';
                track.style.top = '0px';
            } else {
                let H = slideTrack.clientHeight;
                let L = slideTrack.scrollHeight;
                let S = L - H;

                let T = parseInt(getComputedStyle(track, null).getPropertyValue("top"));
                let _S = 0;

                if (T < 0) {
                    if (-T > H) {
                        _S += H;
                    } else {
                        _S -= T;
                    }
                }
                track.style.top = (T + _S) + 'px'
            }
            
        }
    }

    const trackUp = () => {
        let slideTrack = document.querySelector('.slide-track');
        let track: any = document.querySelector('.track');
        if (slideTrack && track) {
            let style = getComputedStyle(track);
            let sliderType:string = style.getPropertyValue('flex-direction');

            if(sliderType == 'row'){
                let W = slideTrack.clientWidth;
                let SW = slideTrack.scrollWidth;
                let S = SW - W;

                let L = parseInt(getComputedStyle(track, null).getPropertyValue("left"));
                let R = S + L;

                let scroll = 0;
                if(R > W){
                    scroll -= W;
                } else if(W >= R) {
                    scroll -= R;
                }

                track.style.left = (L + scroll) + 'px';
                track.style.top = '0px';
            } else {
                let H = slideTrack.clientHeight;
                let L = slideTrack.scrollHeight;
                let S = L - H;

                let T = parseInt(getComputedStyle(track, null).getPropertyValue("top"));
                let R = S + T;
                let _S = 0;

                if (R > H) {
                    _S -= H;
                } else if (H >= R) {
                    _S -= R;
                }
                track.style.top = (T + _S) + 'px';
                track.style.left = '0px'
            }
            
        }
    }

    const openPellet = (inputColor:any)=>{
        //currentTexture.original;
        setInputColor(inputColor.replace('#',''))
        setOwnerPallete(true);
    }

    const changeImageColor = (o: any, i: any) => {
        let new_colors = JSON.parse(JSON.stringify(textureColors));
        new_colors = new_colors.map((color: any, index: any) => {
            if (color == '#' + inputColor) {
                return o;
            } else {
                return color;
            }
        });

        set_textureColors(new_colors);

        setOwnerPallete(false);
        axios({
            method: 'GET',
            url: API_URL.CHANGE_IMAGE_COLOR,
            params:{
                i:inputColor,
                o:o.replace('#',''),
                f:parseFloat(design.fuzz),
                g:60,
                'imgpath':textureMapImage
            }
        }).then(res => {
            set_textureMapImage(res.data.data.ColorChanged) ;
            /* setTimeout(() =>{
                changeTextureColor()
            },2000) */
        }).catch(err => {
        })
    }

    const getUserDetails = () => {
        setModal(true);
    }

    const onUser = (data:any)=>{
        addToCart(data);
    }

    const addToCart = (data: any) => {
        //downloadTshirt();

        //return;
        camera.aspect = 500 / 500;
        camera.updateProjectionMatrix();
        renderer.setSize(500, 500);
        renderer.render(scene, camera);
        camera.lookAt(0, 0, 0);

        camera.position.set(0, 0, 1500);
        control.update();
        setTimeout(() => {
            let frontView = renderer.domElement.toDataURL("image/jpeg");
            camera.position.set(0, 0, -1500);
            control.update();
            axios.post(API_URL.ADD_TO_CART, {
                "custname": data.custname,
                "lname": data.lname,
                "email": data.email,
                "password": data.password,
                "name": design.title,
                "sku": 'SKU'+(new Date()).getTime(),
                "quantity": "1",
                "description": "This is description",
                "price": currentTexture?.texture_price,
                "size": data.size,
                "color": textureColors.map((c:any)=>{return colorsName[c]}).join(","),
                "proimage": frontView
            }).then(function (response) {
                if (response.data.inserted._id) {
                    window.location.href = 'https://elisdyes.wixsite.com/website/customlogin';
                } else {
                    alert('Something wrong! Please try after some times.')
                }

            }).catch(function (error) {
            });

            setTimeout(() => {
                onWindowResize();
            }, 100)
        }, 100)
    }



    useEffect(() => {
        
    }, [mount]);


    useEffect(() => {
        getModel();

        return () => {
            

        }
    }, []);

    useEffect(() => {
        if (model)
            setTextureCanvas(document.getElementById('texture'));
    }, [model]);

    useEffect(() => {
        if (textureCanvas) {
            createTexture([
                { path: '/assets/textures/white.jpg', width: 1024, height: 1024, x: 0, y: 0, alpha: 1 },
                getLogo(),
            ]).then((data) => {
                createVariables();
            })
        }
    }, [textureCanvas]);

    useEffect(() => {
        if (scene && camera && renderer && loader && logo) {
            if (!render) {
                prepareScene();
            }
        }
    }, [scene, camera, renderer, loader, logo]);

    useEffect(() => {
        if (render) {
            changeTextureColor();
        }
    }, [logo, logoPosition, logoSize, logoPosition, textureMapImage]);
    useEffect(() => {
        if (render) {

            set_textureMapImage(currentTexture?.original);
        }
    }, [currentTexture]);






    useEffect(() => {
        if (texture) {
            texture.needsUpdate = true;
            if (object) {
                object.traverse(function (child: any) {
                    if (child.isMesh) {
                        child.material.map.needsUpdate = true;
                    }
                });
            }
        }
    }, [texture]);

    useEffect(() => {
        if (object) {
            loadModel();
        }
    }, [object]);

    useEffect(() => {
        if (manager) {
            manager.onProgress = (item: any, loaded: any, total: any) => {
            };
            setLoader(new OBJLoader(manager));
        }
    }, [manager]);

    ReactTooltip.rebuild();
    return (
        <React.Fragment>
            <Modal
                show={modal}
                onHide={modalClose}
                onUser={onUser}
            />
            <canvas id="texture" width="1024" height="1024"></canvas>
            <div className="design-container">
                <div className="header">
                    <img src="/assets/images/logo.png" alt="" />
                    <div>
                        {/* <FaDownload className="semi-big-icon " onClick={downloadTshirt} /> */}
                        <FaArrowLeft className="big-icon" style={{ marginRight: '15px' }} onClick={back} />
                    </div>
                </div>
                <Container fluid className="body">
                    <Row>
                        <Col lg={2} className="left-pannel">
                            <div className="left-container">
                                <label className="bg-primary">Choose Pattern</label>
                                <div className="patterns">
                                    <div className="prev"><IoIosArrowUp className="big-icon" onClick={trackDown} /></div>
                                    <div className="slide-track">
                                        <div className="track">
                                            {model ? model.textures.map((pattern: any, i: any) => (
                                                <div className="pattern" key={i} onClick={() => { loadDesign(pattern) }}>
                                                    <img src={pattern.texture_main_image_links.original} alt="" />
                                                </div>
                                            )) : <Loader />}
                                        </div>
                                    </div>
                                    <div className="next"><IoIosArrowDown className="big-icon" onClick={trackUp} /></div>
                                </div>
                            </div>
                            
                        </Col>
                        <Col lg={8} className="canvas" id="canvas">
                     
                            <div className="scroller"><CgScrollV /></div>
                            <svg className="_360" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 512 512" version="1.1">
                                <g id="#000000ff">
                                    <path fill="#000000" opacity="0.5" d=" M 446.50 66.72 C 458.75 64.51 471.86 67.62 481.68 75.30 C 492.12 83.23 498.78 95.90 499.27 109.02 C 499.98 122.73 493.89 136.55 483.35 145.34 C 473.97 153.40 461.15 157.20 448.89 155.63 C 434.87 154.00 421.95 145.29 415.11 132.95 C 408.42 121.19 407.54 106.36 412.76 93.89 C 418.43 79.88 431.60 69.27 446.50 66.72 M 450.38 96.56 C 442.64 98.53 437.50 107.37 439.94 115.04 C 441.95 122.78 450.84 127.87 458.50 125.37 C 466.07 123.35 471.07 114.74 468.82 107.18 C 466.94 99.39 458.09 94.18 450.38 96.56 Z" />
                                    <path fill="#000000" opacity="0.5" d=" M 109.98 164.06 C 120.51 156.75 133.94 155.54 146.39 156.39 C 156.69 157.18 167.76 160.09 174.75 168.21 C 181.95 176.56 182.88 188.45 181.29 198.93 C 179.87 207.75 176.03 217.23 167.68 221.56 C 176.42 225.18 184.29 232.24 185.95 241.95 C 187.69 253.68 186.99 266.50 180.90 276.96 C 175.46 286.35 165.39 292.13 155.00 294.41 C 140.75 297.34 125.05 297.03 111.94 290.10 C 99.83 283.78 91.53 269.73 93.90 255.99 C 94.66 251.23 99.90 249.60 104.07 249.55 C 109.41 249.47 115.38 248.91 120.06 251.96 C 124.23 255.28 121.30 261.53 124.56 265.37 C 127.73 269.33 133.23 270.04 137.97 270.28 C 143.22 270.33 149.26 270.23 153.27 266.31 C 157.16 262.25 157.05 256.22 157.11 250.97 C 157.21 246.21 156.50 240.67 152.39 237.62 C 147.94 234.24 142.09 234.77 136.86 234.15 C 132.26 233.29 130.39 228.18 130.34 224.01 C 130.09 219.55 131.24 213.72 136.03 212.09 C 140.33 211.11 145.34 212.12 149.04 209.14 C 153.38 205.48 153.74 199.21 153.63 193.95 C 153.64 189.06 151.05 183.71 145.97 182.46 C 140.41 181.18 133.97 180.95 128.87 183.85 C 123.74 187.12 127.15 194.32 123.36 198.42 C 120.52 201.60 115.92 201.98 111.93 202.09 C 107.87 202.03 103.57 202.09 99.84 200.23 C 96.60 198.44 96.37 194.30 96.31 191.02 C 95.96 180.48 101.24 169.98 109.98 164.06 Z" />
                                    <path fill="#000000" opacity="0.5" d=" M 219.38 169.50 C 227.18 161.11 238.72 157.39 249.88 156.47 C 262.32 155.42 275.63 156.81 286.29 163.80 C 296.01 170.06 302.54 181.40 302.00 193.08 C 301.90 196.59 300.64 200.60 297.20 202.17 C 293.09 204.12 288.35 203.94 283.93 203.57 C 280.34 203.12 276.34 201.98 274.30 198.73 C 271.95 195.28 273.76 190.42 270.77 187.25 C 265.69 181.78 257.30 181.32 250.39 182.62 C 244.03 183.84 239.49 189.65 238.80 195.93 C 237.99 203.26 238.63 210.66 238.39 218.02 C 243.47 212.64 250.84 210.18 258.10 209.85 C 269.12 209.23 281.10 210.82 289.92 218.02 C 297.87 224.53 301.32 234.97 301.87 244.94 C 302.63 257.20 301.30 270.48 293.67 280.58 C 286.79 289.87 275.32 294.47 264.12 295.66 C 252.65 296.82 240.57 296.04 230.05 290.97 C 222.41 287.32 216.16 280.88 212.84 273.08 C 209.64 265.83 208.75 257.80 208.78 249.95 C 208.79 233.29 208.72 216.64 208.81 199.99 C 208.91 189.09 211.71 177.61 219.38 169.50 M 248.36 233.49 C 244.88 234.50 241.86 237.00 240.32 240.30 C 238.12 244.82 238.35 250.01 238.46 254.91 C 238.62 259.76 239.89 264.98 243.63 268.34 C 247.79 272.10 253.81 272.59 259.14 271.94 C 263.54 271.45 267.82 268.98 269.92 265.00 C 272.70 259.87 272.61 253.78 272.25 248.12 C 271.87 242.99 269.89 237.39 265.16 234.74 C 260.08 231.98 253.84 232.08 248.36 233.49 Z" />
                                    <path fill="#000000" opacity="0.5" d=" M 365.23 156.41 C 378.87 155.42 393.84 157.19 404.70 166.25 C 414.28 174.15 418.09 186.96 418.28 199.00 C 418.42 216.31 418.32 233.63 418.33 250.95 C 418.37 262.12 415.91 273.95 408.31 282.51 C 400.78 291.15 389.22 294.99 378.09 295.87 C 366.57 296.72 354.41 295.65 344.17 289.89 C 337.00 285.90 331.38 279.31 328.49 271.64 C 325.68 264.47 325.00 256.69 325.05 249.05 C 325.08 232.66 325.00 216.28 325.09 199.90 C 325.21 189.04 327.86 177.61 335.36 169.40 C 342.90 161.03 354.25 157.23 365.23 156.41 M 365.48 182.77 C 358.32 184.52 354.75 192.14 354.76 198.99 C 354.60 216.31 354.74 233.64 354.69 250.97 C 354.68 256.39 355.62 262.41 359.75 266.32 C 363.51 269.91 369.06 270.60 374.04 270.17 C 378.36 269.89 382.74 267.95 385.28 264.34 C 388.00 260.51 388.68 255.67 388.70 251.08 C 388.70 234.37 388.71 217.65 388.69 200.94 C 388.69 195.49 387.54 189.48 383.26 185.73 C 378.43 181.57 371.40 181.39 365.48 182.77 Z" />
                                    <path fill="#000000" opacity="0.5" d=" M 55.78 205.77 C 62.51 201.58 72.21 204.11 76.17 210.95 C 80.35 217.61 78.09 227.23 71.38 231.33 C 59.04 238.81 47.06 247.57 38.55 259.41 C 32.82 267.36 28.97 277.22 30.28 287.15 C 31.64 298.32 38.32 308.02 46.01 315.90 C 57.83 327.74 72.39 336.46 87.42 343.58 C 123.65 360.42 163.24 368.78 202.78 373.01 C 198.68 368.83 194.41 364.81 190.41 360.54 C 185.14 354.71 185.74 344.83 191.65 339.67 C 197.21 334.49 206.67 334.54 212.07 339.92 C 225.61 353.39 239.13 366.90 252.59 380.45 C 258.14 386.04 257.97 395.92 252.31 401.38 C 239.37 414.41 226.33 427.34 213.38 440.36 C 211.32 442.45 208.90 444.30 206.02 445.09 C 199.37 447.21 191.45 443.93 188.43 437.59 C 185.44 431.98 186.62 424.58 191.17 420.14 C 196.56 414.68 202.07 409.32 207.43 403.83 C 199.29 402.62 191.06 402.10 182.93 400.85 C 144.69 395.46 106.63 386.14 71.72 369.32 C 53.21 360.23 35.42 348.90 21.45 333.56 C 9.59 320.72 1.13 304.28 0.00 286.64 L 0.00 279.56 C 0.77 270.70 2.98 261.94 6.92 253.94 C 17.20 232.90 36.01 217.54 55.78 205.77 Z" />
                                    <path fill="#000000" opacity="0.5" d=" M 447.98 214.06 C 452.83 209.29 460.97 208.53 466.60 212.37 C 476.96 219.44 486.59 227.72 494.50 237.48 C 504.63 249.85 511.48 265.23 512.00 281.35 L 512.00 286.50 C 510.94 305.19 501.61 322.49 488.70 335.71 C 474.27 350.67 456.30 361.74 437.64 370.61 C 402.19 387.29 363.62 396.32 324.93 401.42 C 317.31 402.86 309.24 397.57 307.71 389.95 C 305.60 381.90 311.60 373.07 319.79 371.89 C 358.64 366.65 397.59 357.64 432.64 339.60 C 447.58 331.68 462.12 321.99 472.51 308.42 C 479.46 299.34 483.88 287.49 481.19 276.03 C 478.01 261.98 467.63 250.97 456.67 242.28 C 452.97 239.05 448.12 236.94 445.53 232.58 C 441.93 226.83 442.96 218.65 447.98 214.06 Z" />
                                </g>
                            </svg>
                            <div className={"loader" + (workingLoader ? ' active' : '')}>
                                <Loader />
                            </div>

                            {/* <div className="palette">
                                <div className="colors" >
                                    {textureColors?.map((color: any, i: any) => (
                                        <div style={{ "backgroundColor": color }} key={i} onClick={() => { openPellet(color) }}></div>
                                    ))}
                                </div>
                                {currentTexture? <label className="text-primary">Update Pattern Color</label>:null}
                                
                            </div> */}

                            



                        </Col>
                        <Col lg={2} className="right-pannel">
                        {currentTexture? 
                        <div className={"right-container"+(ownerPallete?' d-none':'')}>
                                <div className="right-palette">
                                    <div className="colors" >
                                        {textureColors?.map((color: any, i: any) => (
                                            <div style={{ "backgroundColor": color }} key={i} data-tip={colorsName[color]} onClick={() => { openPellet(color) }}></div>
                                        ))}
                                    </div>
                                    {currentTexture? <label className="text-primary">Update Pattern Color</label>:null}
                                    
                                </div>
                                <Button variant='dark' size="sm" onClick={() =>{loadDesign(design)}}>Reset Colors</Button>
                                {/* <DesignOptions setLogo={setLogo} setLogoSize={setLogoSize} setLogoPosition={setLogoPosition} update={changeTextureColor} /> */}
                                {currentTexture? <React.Fragment>
                                    <p className="price">Price: ${currentTexture?.texture_price}</p>
                                    <Button onClick={getUserDetails} >Add to Cart</Button>
                                </React.Fragment>: null}
                            </div>
                        :null}
                            
                            
                            
                        </Col>
                    </Row>

                    
                </Container>

                <div className={"owner-palette " + (ownerPallete ? 'active' : '')}>
                    <div className="colors">
                        {ownerColors.map((color: any, i: any) => (
                            <div className="color" data-tip={colorsName[color]} style={{ "backgroundColor": color }} key={i} onClick={() => { changeImageColor(color,i) }}></div>
                        ))}
                    </div>
                    <div className="close" onClick={() => { setOwnerPallete(false) }}><FaTimes /></div>
                </div>
            </div>
        </React.Fragment>
    )
}