//import { getSubdomain } from './functions';
import { API_BASE_URL } from './site_urls'

export const STORAGE = 'ws_iot_ui'
export const APP_STAGE = process.env.REACT_APP_STAGE
export const APP_VERSION = process.env.REACT_APP_VERSION

export const APP_URL = APP_STAGE === 'dev' ? window.location.origin : window.location.origin;

export const URLS = {
  /* HOME: '/',
  USER: {
    LOGIN: '/login',
  } */
}

export const API = {}


export const API_URL = {
  PRODUCT: {
    GET_CATEGORY: `${API_BASE_URL}products/category/`,
    GET_MODEL: `${API_BASE_URL}products/`,
    GET_IMAGE_DATA: `${API_BASE_URL}image-convert-to-base64?imageurl=`,
  },
  CHANGE_IMAGE_COLOR:`${API_BASE_URL}change-image-color`,
  WIX_LOGIN_CHACKER:`${API_BASE_URL}wix-login-checker`,
  ADD_TO_CART:`${API_BASE_URL}add-to-cart`,
}