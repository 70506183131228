import React, { useEffect, useState } from "react";
import { GiCreditsCurrency, GiMoneyStack } from 'react-icons/gi';
import { FiEdit } from 'react-icons/fi';
import { BsTagFill } from 'react-icons/bs';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { API_URL } from '../_config';

const Modal = (props: Record<string, any>) => {

    const [loading, set_loading] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors } } = useForm();


    const onSubmit = (data: any) => {
        set_loading(true);
        axios({
            method: 'POST',
            url: API_URL.WIX_LOGIN_CHACKER,
            data: {
                email: data.email,
                password: data.password
            }
        }).then(res => {
            
            if (res.data.inserted.err) {
                switch (res.data.inserted.msg) {
                    case 'user not register':
                        props.onUser(data);
                        //props.onHide();
                        break;
                    case 'Password didnot match':
                        alert('password did not match');
                        set_loading(false);
                        break;
                }
            } else {
                props.onUser(data);
                //props.onHide();
            }
        }).catch(err => {
            //setModel(null);
            set_loading(false);
        })



        /* let cp: any = document.getElementById('c_password');
        if (data.password === cp?.value) {
            showLoader();
            userApi.callRegistration(data, (message: string, resp: any) => {
                hideLoader();
                toast.success(message);
                history.push(URLS.HOME);
            }, (message: string) => {
                toast.error(message);
                hideLoader();
               
            })
        } else {
            setMatch(false)
        } */

    }

   

    return (
        <React.Fragment>
            {props.show ?
                <div className="user-modal">
                    <div className="cart pageCenter">
                        <h2>Move to Cart</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        {/* <label htmlFor="username">First Name</label> */}
                                        <input type="text" className={"form-control f_line" + (errors?.custname ? ' is-invalid' : '')} placeholder="First Name" {...register("custname", { required: true, maxLength: 80 })} />
                                        {errors?.custname ? <div className="invalid-feedback">required</div> : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group  position-relative">
                                        {/* <label htmlFor="username">Last Name</label> */}
                                        <input type="text" className={"form-control f_line" + (errors?.lname ? ' is-invalid' : '')} placeholder="Last Name" {...register("lname", { required: true, maxLength: 80 })} />
                                        {errors?.lname ? <div className="invalid-feedback">required</div> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group  position-relative">
                                        {/* <label htmlFor="username">Email</label> */}
                                        <input type="text" className={"form-control f_line" + (errors?.email ? ' is-invalid' : '')} placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                        {errors?.email ? <div className="invalid-feedback">
                                            {errors.email.type === "required" ? 'required' : null}
                                            {errors.email.type === "pattern" ? 'invalid' : null}
                                        </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group  position-relative">
                                        {/* <label htmlFor="userpassword">Password</label> */}
                                        <input type="password" className={"form-control f_line" + (errors?.password ? ' is-invalid' : '')} placeholder="Password" {...register("password", { required: true })} />
                                        {errors?.password ? <div className="invalid-feedback">
                                            {errors.password.type === "required" ? 'required' : null}
                                            {errors.password.type === "minLength" ? 'Minimum 8 characters' : null}
                                            {errors.password.type === "maxLength" ? 'Maximum 16 characters' : null}
                                            {errors.password.type === "pattern" ? 'Password need uppercase, lowercase, number, special character' : null}
                                        </div> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group  position-relative">
                                        {/* <label htmlFor="userpassword">Password</label> */}
                                        <select className={"form-select" + (errors?.size ? ' is-invalid' : '')} {...register("size", { required: true })}>
                                            <option value="">Select Size</option>
                                            <option value="XS">Extra Small</option>
                                            <option value="S">Small</option>
                                            <option value="M">Medium</option>
                                            <option value="L">Large</option>
                                            <option value="XL">Extra Large</option>
                                        </select>
                                        {errors?.size ? <div className="invalid-feedback">
                                            {errors.size.type === "required" ? 'required' : null}

                                        </div> : null}
                                    </div>
                                </div>
                            </div>




                            <div className="btns">
                                {loading ?
                                    <svg xmlns="http://www.w3.org/2000/svg"  style={{margin: 'auto', display: 'block'}} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                        <g transform="translate(50 50)">  <g transform="translate(-19 -19) scale(0.6)"> <g>
                                            <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1" dur="0.25510204081632654s" begin="0s" repeatCount="indefinite" /><path d="M28.75271449762364 18.146112779825422 L37.23799587186221 26.63139415406399 L26.631394154063997 37.2379958718622 L18.146112779825426 28.752714497623636 A34 34 0 0 1 7.499999999999999 33.16247879758086 L7.499999999999999 33.16247879758086 L7.5 45.16247879758086 L-7.499999999999994 45.16247879758086 L-7.499999999999995 33.16247879758086 A34 34 0 0 1 -18.146112779825415 28.752714497623643 L-18.146112779825415 28.752714497623643 L-26.631394154063983 37.23799587186221 L-37.23799587186221 26.631394154063994 L-28.75271449762364 18.146112779825422 A34 34 0 0 1 -33.16247879758086 7.500000000000008 L-33.16247879758086 7.500000000000008 L-45.16247879758086 7.500000000000009 L-45.16247879758086 -7.5 L-33.16247879758086 -7.500000000000001 A34 34 0 0 1 -28.752714497623643 -18.146112779825415 L-28.752714497623643 -18.146112779825415 L-37.23799587186222 -26.631394154063983 L-26.63139415406401 -37.237995871862196 L-18.146112779825437 -28.75271449762363 A34 34 0 0 1 -7.4999999999999964 -33.16247879758086 L-7.4999999999999964 -33.16247879758086 L-7.499999999999999 -45.16247879758086 L7.499999999999981 -45.16247879758086 L7.499999999999984 -33.16247879758086 A34 34 0 0 1 18.146112779825426 -28.752714497623636 L18.146112779825426 -28.752714497623636 L26.631394154063994 -37.23799587186221 L37.237995871862196 -26.631394154064008 L28.75271449762363 -18.146112779825437 A34 34 0 0 1 33.16247879758086 -7.499999999999998 L33.16247879758086 -7.499999999999998 L45.16247879758086 -7.500000000000001 L45.16247879758086 7.49999999999998 L33.16247879758086 7.499999999999982 A34 34 0 0 1 28.752714497623636 18.146112779825422 M0 -25A25 25 0 1 0 0 25 A25 25 0 1 0 0 -25" fill="#ffffff" /></g></g> <g transform="translate(19 19) scale(0.6)"> <g>
                                                <animateTransform attributeName="transform" type="rotate" values="45;0" keyTimes="0;1" dur="0.25510204081632654s" begin="-0.12755102040816327s" repeatCount="indefinite" /><path d="M-28.752714497623643 -18.146112779825415 L-37.23799587186222 -26.631394154063983 L-26.63139415406401 -37.237995871862196 L-18.146112779825437 -28.75271449762363 A34 34 0 0 1 -7.4999999999999964 -33.16247879758086 L-7.4999999999999964 -33.16247879758086 L-7.499999999999999 -45.16247879758086 L7.499999999999981 -45.16247879758086 L7.499999999999984 -33.16247879758086 A34 34 0 0 1 18.146112779825426 -28.752714497623636 L18.146112779825426 -28.752714497623636 L26.631394154063994 -37.23799587186221 L37.237995871862196 -26.631394154064008 L28.75271449762363 -18.146112779825437 A34 34 0 0 1 33.16247879758086 -7.499999999999998 L33.16247879758086 -7.499999999999998 L45.16247879758086 -7.500000000000001 L45.16247879758086 7.49999999999998 L33.16247879758086 7.499999999999982 A34 34 0 0 1 28.752714497623636 18.146112779825422 L28.752714497623636 18.146112779825422 L37.23799587186221 26.63139415406399 L26.63139415406401 37.237995871862196 L18.14611277982544 28.75271449762363 A34 34 0 0 1 7.5 33.16247879758086 L7.5 33.16247879758086 L7.499999999999998 45.16247879758086 L-7.500000000000011 45.16247879758086 L-7.500000000000009 33.16247879758086 A34 34 0 0 1 -18.14611277982537 28.752714497623668 L-18.14611277982537 28.752714497623668 L-26.631394154063926 37.237995871862246 L-37.23799587186218 26.63139415406402 L-28.75271449762362 18.14611277982544 A34 34 0 0 1 -33.16247879758085 7.500000000000031 L-33.16247879758085 7.500000000000031 L-45.16247879758085 7.5000000000000355 L-45.16247879758086 -7.500000000000003 L-33.16247879758086 -7.500000000000007 A34 34 0 0 1 -28.75271449762364 -18.14611277982542 M0 -25A25 25 0 1 0 0 25 A25 25 0 1 0 0 -25" fill="#671821" /></g></g></g>
                                    </svg>
                                    : <React.Fragment>

                                        <button className="danger" onClick={props.onHide}>Cancel</button>
                                        <button className="success" type="submit">Add to Cart</button>

                                    </React.Fragment>}

                            </div>

                        </form>
                    </div>
                </div>
                : null}
        </React.Fragment>
    )
};
export default Modal;