import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../_config';
import Loader from "../../components/loader";
import { FaTimes, FaDownload, FaArrowLeft } from 'react-icons/fa';
import './css/selectDesign.scss';

 

import { Container, Row, Col, Button } from 'react-bootstrap';

export default function SelectDesign() {

    const history = useHistory();
    const subCat = sessionStorage.getItem('subCategory');
    const Cat = sessionStorage.getItem('category');
    const [products, setProducts] = useState<any>([]);
    const [product, setProduct] = useState<any>(null);

    const back = (e: any) => {
        sessionStorage.removeItem('category');
        sessionStorage.removeItem('subCategory');
        history.push('/');
    }
    const edit = () => {
        if (product) {
            sessionStorage.setItem('modelID', product.id);
            history.push('/design');
        } else {
            alert('Please Select a Model')
        }

    }

    const getModels = () => {
        axios({
            method: 'GET',
            url: API_URL.PRODUCT.GET_CATEGORY + subCat,
        }).then(res => {
            if (res && res.data && res.data.data) {
                let _data = res.data.data;
                setProducts(_data.product)
            } else {
                setProducts([])
            }
        }).catch(err => {
            setProducts([])
        })
    }

    useEffect(() => {
        if(product){
            edit();
        }
    }, [product]);



    useEffect(() => {
        getModels()
    }, []);

    return (
        <section className="bg-primary select-design">
            <div className="box">
                <div className="top-bar">
                    <img src="/assets/images/logo.png" alt="#" />

                    <div>
                        {/* <FaDownload className="semi-big-icon " onClick={downloadTshirt} /> */}
                        <FaArrowLeft className="big-icon" style={{marginRight:'15px'}} onClick={back} />
                    </div>
                </div>
                <h3 className="text-center">Select garment to edit</h3>

                <p className="text-center breadcrumbs">You are here: <span>{Cat}</span> <span className="next">{'>'}</span> <span>{subCat}</span></p>

                <Container fluid>
                    <Row>
                    
                        {products?.map((_product: any, i: any) => (
                            <Col xl={2} lg={3} md={4} sm={6} xs={12} key={i}>
                                <div className={"model" + (product?.id === _product.id ? ' active' : '')} onClick={() => { setProduct(_product) }}>
                                    <img src={_product.product_img.original} alt="" />
                                    <label>{_product.title}</label>
                                </div>
                            </Col>
                        ))}
                        {!products.length?<Loader/>:null}

                    </Row>
                </Container>

                {/* <div className="footer">
                    <Button onClick={back}>Back</Button>
                    <Button onClick={edit}>Continue</Button>

                </div> */}
            </div>

        </section>
    )
}