import React from 'react';
import { Route, Switch } from "react-router-dom";
import './App.scss';

import Home from './pages/home';
import Design from './pages/design';
import SelectDesign from './pages/selectDesign';

import ReactTooltip from 'react-tooltip';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Toaster />
      <ReactTooltip place="bottom"/>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/select-design" exact component={SelectDesign} />
        <Route path="/design" exact component={Design} />
        {/* <Route path="/sample" render={routerProps => <Sample {...routerProps} sampleProp={"sample"}/>} />
        <Route path="/something" component={Something} />
        <Route path='/default' render={() => <Redirect to= "/" />} />
        <Route component={NoMatch} /> */}
      </Switch>
    </div>
  );
}

export default App;
